const yearone = () => { //js本年第一天
    var date = new Date();
    var year = date.getFullYear();
    const state1 = year + '-' + "01" + '-' + "01";
    return state1;
};

const dayone = () => { //js本月第一天
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    const state1 = year + '-' + month + '-' + "01";
    return state1;
};
const daynow = () => { //js当日
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    const state2 = year + '-' + month + '-' + strDate;
    return state2;
};
const Daysevenold = (date) => {
    var date = date || new Date(),
        timestamp,
        newDate;
    if (!(date instanceof Date)) {
        date = new Date(date.replace(/-/g, '/'));
    }
    timestamp = date.getTime();
    newDate = new Date(timestamp - 7 * 24 * 3600 * 1000);
    var month = newDate.getMonth() + 1;
    month = month.toString().length == 1 ? '0' + month : month;
    var day = newDate.getDate().toString().length == 1 ? '0' + newDate.getDate() : newDate.getDate();
    return [newDate.getFullYear(), month, day].join('-');
} 
const Daysevennow = () => {
    var date = new Date();
    var year7 = date.getFullYear();
    var month7 = date.getMonth() + 1;
    var strDate7 = date.getDate() + 7;
    if (month7 >= 1 && month7 <= 9) {
        month7 = "0" + month7;
    }
    if (strDate7 >= 0 && strDate7 <= 9) {
        strDate7 = "0" + strDate7;
    }
    const state7 = year7 + '-' + month7 + '-' + strDate7;
    return state7;
}
 //7天后
const getBeforeDate = (n) => {
    var s = ''
    var n = n;
    var d = new Date();
    var year = d.getFullYear();
    var mon = d.getMonth() + 1;
    var day = d.getDate();
    if (day <= n) {
        if (mon > 1) {
            mon = mon - 1;
        } else {
            year = year - 1;
            mon = 12;
        }
    }
    d.setDate(d.getDate() - n);
    year = d.getFullYear();
    mon = d.getMonth() + 1;
    day = d.getDate();
    s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
    return s;
}

const Lastdayone = () => { //js上月第一天
    var nowdays = new Date();
    var year = nowdays.getFullYear(); //得到年份
    var month = nowdays.getMonth(); //得到月份
    if (month === 0) {
        month = 12;
        year = year - 1;
    }
    if (month < 10) {
        month = "0" + month;
    }
    var firstDay = year + "-" + month + "-" + "01";
    return firstDay;
};
const Lastdaynow = () => { //上个月的最后一天
    var nowdays = new Date();
    var year = nowdays.getFullYear(); //得到年份
    var month = nowdays.getMonth(); //得到月份
    if (month === 0) {
        month = 12;
        year = year - 1;
    }
    if (month < 10) {
        month = "0" + month;
    }
    var myDate = new Date(year, month, 0); //上个月最后一天
    var lastDay = year + "-" + month + "-" + myDate.getDate();
    return lastDay;
};
//格式化时间的函数  。它接受一个数字类型的参数 time，表示需要格式化的时间。
//如果 time 小于10，则返回一个带有前导零的字符串表示；
//否则，将 time 转换为字符串后返回。
function formatTime(time) {
    return time < 10 ? `0${time}` : time
}
function MydateTime() {
    var s1 = ''
    const randNum = Math.floor(Math.random() * 10000)//4位随机数
    const now = new Date()
    const year = now.getFullYear() //年
    const month = now.getMonth() + 1 //月
    const day = now.getDate() //日
    const hours = now.getHours() //小时数
    const minutes = now.getMinutes() //分钟数
    const seconds = now.getSeconds() //秒数
    // const week = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"][now.getDay()] //星期
    //想展示什么  对应的展示即可 
    s1 = `${year}${formatTime(month)}${formatTime(day)}${formatTime(hours)}${formatTime(minutes)}${formatTime(seconds)}${randNum}`
    return s1
}//https: //blog.csdn.net/weixin_44096999/article/details/131207411
export default {
    yearone,
    dayone,
    daynow,
    Lastdayone,
    Lastdaynow,
    Daysevennow,
    getBeforeDate,
    MydateTime,
}