import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import installElementPlus from './plugins/element'

import lrz from 'lrz' //图片压缩
import 'windi.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
installElementPlus(app)
app.use(router)
app.mount('#app')