<template>
	<div class="page-box">
        <div class="head-box">
          <img style="width: 100%; height: 250px" src="../assets/imgs/iblogheader.png"/>
    	</div>
        <div class="content-box">
          <el-main>
          <div class=" mt-1 py-2">
            <el-scrollbar ref="scrollArea"  class="scroll-container" @mouseenter="stopScroll" @mouseleave="startScroll">
              <div class="scroll-content">
                <div v-if="displayMode =='卡片显示'">
                <el-card class="box-card" v-for="(item,index) in tableData_xm_gx" :key="index" @click="tableRowClick(item)" style="margin:6px;">
                  <template #header>
                    <div class="card-header">
                      <span class="text-3xl text-amber-500">{{index+1}}:{{item.item1}}</span>
                    </div>
                  </template>
                  <span v-for="(list,indx) in item" :key="indx" class="text item">
                    <span v-if="indx>='item2' && indx<='item3'" class="text-2xl text-teal-500">{{ list }}/</span>
                  </span>
                </el-card>
              </div>
            </div>
          </el-scrollbar>
          </div>
        </el-main>
    	</div>
        <div class="footer-box">
          <div class="bg-gray-700 h-[40px] text-light-100 mt-4 text-center items-center">
                网站备案号 
                <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备19004914号</a>
                ,联系电话:135 0592 0590
                <a href="https://beian.miit.gov.cn/" target="_blank">工信部备案官网首页https://beian.miit.gov.cn</a>
        </div>
    	</div>
    </div>
</template>
<script setup>
import myGtdatafun from "../myapi/myGtdatafun.js";
import dateglobalfun from "../../dateglobalfun.js"; //日期函数
import data_js from "../myapi/formData.js";
import { ref, reactive, onMounted, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import lzqTable from "../components/truecomponents/lzqTable.vue"; //引入自定义组件
const tableData_xm_gx = reactive([]);
const tableData_xm_gx_base = reactive([]);
const tableData_ckrbb = reactive([]); //注意这个定义,搞得半死!!
const tableData_ckrbb_base = reactive([]); //注意这个定义,搞得半死!!
const route = useRoute();
const router = useRouter();
const dialogFormVisible_dj = ref(true);
const dialogFormVisible_pm = ref(false);
const dialogFormVisible_xgsctj = ref(false);
const dialogFormVisible_kfzl = ref(false);
const drawer_RtoL_kh = ref(false);
const drawer_RtoL_pm = ref(false);
const myloading = ref(false);
const my_row = ref();
const infoTxt = ref("新增一行");
const displayMode = ref("卡片显示");
const inputVal3 = ref("");

const dh = dateglobalfun.MydateTime();
const zh_options = reactive([]); //注意这个定义,搞得半死!!
const MYheight2 = ref(600);
let formData = ref({});
const oldCztj = ref(""); //旧的查找条件
onMounted(() => {
  //页面进入时
  formData.value=data_js.formData_js;
  // console.log(JSON.parse(route.query.my_row),formData.value);
  // my_row.value=JSON.parse(route.query.my_row)
  // formData.value.dh=dh//dateglobalfun.MydateTime()
  // formData.value.khmc=my_row.value.item1
  // formData.value.shdz=my_row.value.item2
  // formData.value.jgkhid=my_row.value.item3//加工客户id
  formData.value.yhmc = ""; // localStorage.getItem(myGtdatafun.XMMC + "yhtoken");
  formData.value.xm = ""; // localStorage.getItem(myGtdatafun.XMMC + "xm"); //姓名
  formData.value.gjgx = ""; // localStorage.getItem(myGtdatafun.XMMC + "gx"); //工价工序
  formData.value.cztj = "初始获取人生格言";
  khjczl_get();
});
const khjczl_get = async () => {
  //提交到数据库
  myGtdatafun
    .MygettableData("html_dysj_ZSG.php", formData.value)
    .then(function (response) {
      myloading.value = false;
      if (response.length <= 0) {
        return;
      }
      switch (formData.value.cztj) {
        case "初始获取人生格言":
          tableData_xm_gx.length = 0;
          tableData_xm_gx_base.length = 0;
          response.forEach((item) => {
            tableData_xm_gx.push(item);
            tableData_xm_gx_base.push(item);
          });
          break;
        case "保存修改一行发货单":
        case "删除一行发货单":
          tableData_xm_gx.length = 0;
          tableData_xm_gx_base.length = 0;
          response.forEach((item) => {
            tableData_xm_gx.push(item);
            tableData_xm_gx_base.push(item);
          });
      }
      // location.reload();//重新加载本页
    })
    .catch(function (error) {});
};
const tableRowClick = (row) => {//点击一行跳转
  if (row.item1 == '查无资料') return
  console.log('修改删除',row);
  dialogFormVisible_xgsctj.value=true;  
  formData.value.pm=row.item1//品名
  formData.value.dw=row.item2//单位
  formData.value.sl=(row.item3*1.00==0?'':row.item3)//数量
  formData.value.dj=row.item4//单价
  formData.value.je=(row.item5*1.00==0?'':row.item5)//金额
  formData.value.rq=row.item6//日期
  formData.value.id=row.item7//单id
};
const displayPmselect = () => {
  //调用产品基础资料
  drawer_RtoL_pm.value = true;
  oldCztj.value = formData.value.cztj;
  formData.value.cztj = "调用产品基础资料";
  khjczl_get();
};

const qh_display_Mode = () => {
  //切换显示
  if (displayMode.value == "表格显示") {
    displayMode.value = "卡片显示";
  } else {
    displayMode.value = "表格显示";
  }
};
</script>
<style>
    page {
		background-color: #FFF;
	}
    .page-box {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
   	.head-box {
		flex-shrink: 0; 
	}
    .content-box {
		flex-grow: 1; 
		overflow: auto;
	}
    .footer-box {
		flex-shrink: 0; 
	}
</style>
