import dateglobalfun from "/dateglobalfun.js"; //日期函数
const formData_js = { //表格生成
    rq: dateglobalfun.getBeforeDate(0),
    dzkhid: "", //订制客户ID
    dzcpid: "", //订制产品ID
    jgkhid: "", //加工客户ID
    jgcpid: "", //加工产品ID
    azdz: "", //安装地址
    azqy: "", //安装区域
    bz: "", //备注
    bm: "", //编码
    clry: "", //测量人员
    dh: "", //单号
    dj: "", //单价
    dw: "个", //单位
    dh: "", //电话
    gd: "", //高度
    gg: "", //规格
    je: "", //金额
    jsr: "", //经手人
    kdrq: "", //开单日期
    khdz: "", //产品地址
    khdh: "", //产品电话
    khmc: "", //产品名称
    kd: "", //宽度
    lldh: "", //联络电话
    lxr: "", //联系人
    lxrdh: "", //联系人电话
    mlhclmc: "", //面料或材料名称
    pm: "", //品名
    spbm: "", //商品编码
    spqm: "", //商品全名
    sjazrq: "", //实际安装日期
    sl: "", //数量
    shdz: "微信", //送货地址
    shdz: "", //送货地址
    xdrq: "", //下单日期
    xh: "", //序号
    yqazrq: "", //要求安装日期
    zdr: "", //制单人
    cztj: "tjjczl", //操作条件
    yhmc: "", //token
    qxxm: "", //权限项目
    id: "1", //
    endrq: "", //结束日期
};
export default {
    formData_js,
};