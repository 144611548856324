<template>
    <!-- 这是封装table的子组件,注意写法,搞死! -->
    <div>
        <el-table
          border
          ref="multipleTableRef"
          class="mytable"
          :data="tableData_hh_dh"
          style="width: 100%"
          :row-key="(row) => row[RowKey]"
          :row-class-name="MytableRowClassName"
          :height="Myheight"
          highlight-current-row
          empty-text="暂无数据"
          @row-click="tableRowClick"
          @selection-change="handleSelectionChange"
        >
        <el-table-column
            v-if="IsSelection"
            type="selection"
            width="55"
            align="center"
            :reserve-selection="true"
          />
          <el-table-column
            v-for="(ite, index) in myGtdatafun.PublicData[mytitle]"
            :key="index"
            :prop="ite.item1"
            :label="ite.item2"
            :width="ite.item3"
          /> 
        </el-table>
    </div>
</template>

<script setup>
    import myGtdatafun from "../../myapi/myGtdatafun.js";
    //import { ref, reactive,toRefs,onMounted,defineProps,defineEmits,computed } from 'vue'
    import { ref, reactive,toRefs,onMounted,computed } from 'vue'//不需要导入,defineProps,defineEmits
    import { ElTable } from "element-plus";
    const multipleTableRef = ref(null);//注意写法,搞死!
    const props = defineProps({// 去掉主动导入，直接使用 defineProps
        tableData_hh_dh:{
            type:Array,
            default:[],
        },
        mytitle:{
            type:String,
            default:'',
        },
        RowKey:{
            type:String,
            default:'item1',
        },
        IsSelection:{
            type:Boolean,
            default:false,
        },
        Myheight:{
            type:Number,
            default:1000,
        },
        MytableRowClassName: {
            type: String,
            default: '',
        }
    })
    //自定义函数，父组件可以触发
    const em=defineEmits(['tableRowClick','handleSelectionChange'])
    const tableRowClick=(row)=>{
        em("tableRowClick",row);
    }
    const handleSelectionChange=(row)=>{
        em("handleSelectionChange",row);
    }
    defineExpose({multipleTableRef}) // 重点！ //注意写法,搞死!
</script>

<style scoped>

</style>