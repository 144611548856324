import axios from 'axios';
import {ElLoading} from "element-plus";
let XMMCbase = 'iblog';
const cn_XMMC = "测试系统"; //项目名称标题
const XMMC = XMMCbase + "_";
const myURL = 'https://lzqyqsoft.cn/iblogxm/' + XMMCbase + 'server/djphp/'
const myimgUrl = 'https://lzqyqsoft.cn/iblogxm/iblogimgs/'
const imgdz = 'iblogimg_'
const sczdimg = 'iblog_sczd_img_'
//开始loading
let loading;

function startLoading() {
    loading = ElLoading.service({
        lock: true,
        text: "拼命加载中...",
        background: 'rgba(0,0,0,0.2)'
    })
}

function endLoading() {
    loading.close()
}
// 请求数据拦截器
axios.interceptors.request.use(config => {
    // console.log("拦截器")
    startLoading()
    return config
})
// 响应数据拦截器
axios.interceptors.response.use(response => {
    endLoading()
    return response
})
//结束loading

const config = {
    baseURL: myURL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
};

const api = axios.create(config);

//封装下post 2022-3-14
api.post = function (url, params) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: config.baseURL + url,
            data: params,
            transformRequest: [
                function (data) {
                    let ret = "";
                    for (let it in data) {
                        ret +=
                            encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
                    }
                    return ret;
                },
            ],
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }).then(response => {
            if (response.status == 200) {
                resolve(response)
            } else {
                reject(response)
            }
        })
    })
}

//公用获取数据fun,所有都调用这个函数获取数据
const MygettableData = (url, tjrr) => {
    return api.post(url, tjrr) 
        .then(function (response) {
            console.log(response.data.sqlStr);
            console.log(response.data.data);
            return response.data.data;
        })
        .catch(function (error) {});
}
const MygettableData2 = (url, tjrr) => {
    return api.post(url, tjrr) 
        .then(function (response) {
            // console.log(response);
            return response.data.data;
        })
        .catch(function (error) {});
}
const reloginRemoveyhtoken = () => { //重新登录删除token
    localStorage.removeItem(XMMC + "yhtoken"); //删除已记录的token
    localStorage.removeItem(XMMC + "xm");
    localStorage.removeItem(XMMC + "mm");
    localStorage.removeItem(XMMC + "gx");
    localStorage.removeItem(XMMC + "qxList");
    localStorage.removeItem(XMMC + "bmcb");
    localStorage.removeItem(XMMC + "drlb");
};
//以下代码可由excel表格自动生成
const iblogData = {
'客户基础资料表头':[
{'item1': 'item1','item2': '客户名称','item3': '200px'},
{'item1': 'item2','item2': '送货地址','item3': '250px'},
{'item1': 'item3','item2': '电话','item3': '150px'},
{'item1': 'item4','item2': 'id','item3': '60px'},
],'产品基础资料表头':[
{'item1': 'item1','item2': '品名','item3': '200px'},
{'item1': 'item2','item2': '单位','item3': '80px'},
{'item1': 'item3','item2': '单价','item3': '80px'},
{'item1': 'item4','item2': 'id','item3': '60px'},
],'账户基础资料表头':[
{'item1': 'item1','item2': '账户简称','item3': '200px'},
{'item1': 'item2','item2': '账号','item3': '150px'},
{'item1': 'item3','item2': '户名','item3': '150px'},
{'item1': 'item4','item2': 'id','item3': '60px'},
],'供应商基础资料表头':[
{'item1': 'item1','item2': '供应商名称','item3': '200px'},
{'item1': 'item2','item2': '联系人','item3': '150px'},
{'item1': 'item3','item2': '地址电话','item3': '350px'},
{'item1': 'item4','item2': 'id','item3': '60px'},
],'计量单位基础资料表头':[
{'item1': 'item1','item2': '名称','item3': '200px'},
{'item1': 'item2','item2': '备注1','item3': '150px'},
{'item1': 'item3','item2': '备注2','item3': '150px'},
{'item1': 'item4','item2': 'id','item3': '60px'},
],'加工客户基础资料表头':[
{'item1': 'item1','item2': '客户名称','item3': '150px'},
{'item1': 'item2','item2': '送货地址','item3': '250px'},
{'item1': 'item3','item2': 'id','item3': '60px'},
],'调用客户基础资料':[
{'item1': 'item1','item2': '客户名称','item3': '200px'},
{'item1': 'item2','item2': '送货地址','item3': '250px'},
{'item1': 'item3','item2': '电话','item3': '150px'},
{'item1': 'item4','item2': 'id','item3': '60px'},
],'调用产品基础资料':[
{'item1': 'item1','item2': '品名','item3': '200px'},
{'item1': 'item2','item2': '单位','item3': '80px'},
{'item1': 'item3','item2': '单价','item3': '80px'},
{'item1': 'item4','item2': 'id','item3': '60px'},
],'调用账户基础资料':[
{'item1': 'item1','item2': '账户简称','item3': '200px'},
{'item1': 'item2','item2': '账号','item3': '150px'},
{'item1': 'item3','item2': '户名','item3': '150px'},
{'item1': 'item4','item2': 'id','item3': '60px'},
],'调用供应商基础资料':[
{'item1': 'item1','item2': '供应商名称','item3': '200px'},
{'item1': 'item2','item2': '联系人','item3': '150px'},
{'item1': 'item3','item2': '地址电话','item3': '350px'},
{'item1': 'item4','item2': 'id','item3': '60px'},
],'加工单品名资料表头':[
{'item1': 'item1','item2': '品名','item3': '150px'},
{'item1': 'item2','item2': '数量','item3': '80px'},
{'item1': 'item3','item2': '单价','item3': '60px'},
{'item1': 'item4','item2': '金额','item3': '100px'},
{'item1': 'item5','item2': '单位','item3': '80px'},
{'item1': 'item6','item2': 'cpid','item3': '80px'},
{'item1': 'item7','item2': 'kfid','item3': '80px'},
{'item1': 'item8','item2': 'djid','item3': '80px'},
],'查询加工单报表表头':[
{'item1': 'item1','item2': '开单日期','item3': '120px'},
{'item1': 'item2','item2': '客户名称','item3': '150px'},
{'item1': 'item3','item2': '品名','item3': '150px'},
{'item1': 'item4','item2': '单位','item3': '80px'},
{'item1': 'item5','item2': '数量','item3': '80px'},
{'item1': 'item6','item2': '单价','item3': '80px'},
{'item1': 'item7','item2': '金额','item3': '120px'},
{'item1': 'item8','item2': '送货地址','item3': '300px'},
],'手机查汇总加工单时调用加工客户资料表头':[
{'item1': 'item1','item2': '客户名称','item3': '200px'},
{'item1': 'item2','item2': '送货地址','item3': '250px'},
],'手机端查询修改删除加工单表头':[
{'item1': 'item1','item2': '日期','item3': '120px'},
{'item1': 'item2','item2': '客户名称','item3': '150px'},
{'item1': 'item3','item2': '品名','item3': '150px'},
{'item1': 'item4','item2': '单位','item3': '80px'},
{'item1': 'item5','item2': '数量','item3': '80px'},
{'item1': 'item6','item2': '单价','item3': '80px'},
{'item1': 'item7','item2': '金额','item3': '120px'},
{'item1': 'item8','item2': '送货地址','item3': '200px'},
{'item1': 'item9','item2': '加工客户ID','item3': '120px'},
{'item1': 'item10','item2': '加工产品ID','item3': '120px'},
{'item1': 'item11','item2': 'id','item3': '60px'},
],'手机输入收款单初始表头':[
{'item1': 'item1','item2': '客户名称','item3': '150px'},
{'item1': 'item2','item2': '未收余额','item3': '100px'},
{'item1': 'item3','item2': '应收','item3': '100px'},
{'item1': 'item4','item2': '已收','item3': '100px'},
{'item1': 'item5','item2': 'id','item3': '80px'},
],'收款修改删除初始获取收款单表头':[
{'item1': 'item1','item2': '日期','item3': '100px'},
{'item1': 'item2','item2': '客户名称','item3': '180px'},
{'item1': 'item3','item2': '金额','item3': '80px'},
{'item1': 'item4','item2': '账户简称','item3': '150px'},
{'item1': 'item5','item2': 'ID','item3': '80px'},
],'输入发货单表头':[
{'item1': 'item1','item2': '品名','item3': '150px'},
{'item1': 'item2','item2': '单位','item3': '80px'},
{'item1': 'item3','item2': '数量','item3': '80px'},
{'item1': 'item4','item2': '单价','item3': '80px'},
{'item1': 'item5','item2': '金额','item3': '100px'},
{'item1': 'item6','item2': '日期','item3': '110px'},
{'item1': 'item7','item2': 'id','item3': '60px'},
],'获取整张发货单':[
{'item1': 'item1','item2': '客户名称','item3': '180px'},
{'item1': 'item2','item2': '单号','item3': '180px'},
{'item1': 'item3','item2': '金额','item3': '80px'},
{'item1': 'item4','item2': '日期','item3': '120px'},
{'item1': 'item5','item2': '品名摘要','item3': '180px'},
],



















};

export default {
    cn_XMMC,
    XMMC,
    myURL,
    MygettableData,
    MygettableData2,
    reloginRemoveyhtoken,
    iblogData,
    myimgUrl,
    imgdz,
    sczdimg,
};