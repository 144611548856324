import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import myGtdatafun from "../myapi/myGtdatafun.js";
import Home from '../views/Home.vue'
/**换项目时yhtoken需要更改 */
const routes = [{
    path: '/',
    redirect: "/index"
  },
  {
    path: '/index',
    name: 'Index',
    component: Home
  },
  {
    path: '/yhgl',
    name: 'yhgl',
    component: () => import('@/components/dj/Yhgl.vue')
  },
  {
    path: '/a_a_zhjczl',
    name: 'a_a_zhjczl',
    component: () => import('@/components/dj/a_a_zhjczl.vue')
  },
  {
    path: '/a_a_gysjczl',
    name: 'a_a_gysjczl',
    component: () => import('@/components/dj/a_a_gysjczl.vue')
  },
  {
    path: '/a_a_khjczl',
    name: 'a_a_khjczl',
    component: () => import('@/components/dj/a_a_khjczl.vue')
  },
  {
    path: '/a_a_cpjczl',
    name: 'a_a_cpjczl',
    component: () => import('@/components/dj/a_a_cpjczl.vue')
  },

  {path: '/a_a_jldwjczl',name: 'a_a_jldwjczl',component: () => import('@/components/dj/a_a_jldwjczl.vue')},
  {path: '/dj_fhd_1',name: 'dj_fhd_1',component: () => import('@/components/dj/dj_fhd_1.vue')},
  {path: '/dj_fhd_2',name: 'dj_fhd_2',component: () => import('@/components/dj/dj_fhd_2.vue')},
  {path: '/dj_fhd_3',name: 'dj_fhd_3',component: () => import('@/components/dj/dj_fhd_3.vue')},
  {path: '/dj_fhd_4',name: 'dj_fhd_4',component: () => import('@/components/dj/dj_fhd_4.vue')},

  {
    path: '/a_jgd_2_1',
    name: 'a_jgd_2_1',
    component: () => import('@/components/dj/a_jgd_2_1.vue')
  },
  {
    path: '/a_jgd_2_2',
    name: 'a_jgd_2_2',
    component: () => import('@/components/dj/a_jgd_2_2.vue')
  },
  {
    path: '/a_jgd_2_3',
    name: 'a_jgd_2_3',
    component: () => import('@/components/dj/a_jgd_2_3.vue')
  },
  {
    path: '/a_skd_1',
    name: 'a_skd_1',
    component: () => import('@/components/dj/a_skd_1.vue')
  },
  {
    path: '/a_skd_2',
    name: 'a_skd_2',
    component: () => import('@/components/dj/a_skd_2.vue')
  },
  {
    path: '/gzbb1',
    name: 'gzbb1',
    component: () => import('@/components/dj/gzbb1.vue')
  },
  {
    path: "/:catchAll(.*)",
    name: "/404",
    component: () => import("../views/404.vue")
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
// router.beforeEach((to, from, next) => {
//   // const isLogin = localStorage.dhdxclxt_yhtoken ? true : false;
//   const isLogin = localStorage.getItem(myGtdatafun.XMMC + "yhtoken") ? true : false;//20231110最新修改成功,以后只要改一个地方了!!
//   if (to.path === '/yhgl') {
//     next();
//   } else {
//     isLogin ? next() : next('/yhgl');
//   }
// });

export default router